import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const ForgotPasswordPage = styled(IonPage)`
  ion-select,
  input,
  textarea {
    padding-left: 8px !important;
  }
`;
