import { signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { auth } from '../../../config/firebase.config';
import { AuthAction } from '../../../core/context/AuthContext';
import { useAuthContext } from './useAuthContext';
import { useHandleError } from './useHandleError';

export const useLogin = () => {
  const [loginError, setError] = useState('');
  const { dispatch } = useAuthContext();
  const { message } = useHandleError();

  const loginWithEmailPassword = async (email: string, password: string) => {
    setError('');

    try {
      const res = await signInWithEmailAndPassword(auth, email, password);

      if (dispatch) {
        dispatch({ type: AuthAction.Login, payload: res.user });
      }
    } catch (err: any) {
      setError(message(err));
      throw err;
    }
  };

  const updateUser = (user: any) => {
    if (dispatch) {
      dispatch({ type: AuthAction.Login, payload: user });
    }
  };

  return { loginWithEmailPassword, loginError, updateUser };
};
