import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { calendarOutline, pencilOutline, settingsOutline } from 'ionicons/icons';
import { Redirect, Route } from 'react-router';
import { AppConfig } from '../../config/app.config';
import { useAuthContext } from '../auth/hooks/useAuthContext';
import Profile from '../auth/pages/Profile';
import Settings from '../Settings/Settings';
import Subscription from '../subscription/Subscriptions';
import SubscriptionsError from '../subscription/SubscriptionsError/SubscriptionsError';
import SubscriptionsSuccess from '../subscription/SubscriptionsSuccess/SubscriptionsSuccess';
import History from './History';
import Scribble from './Scribble/Scribble';
import Templates from './Templates';

const MemberTabs = () => {
  const { user } = useAuthContext();

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/member/scribble" exact>
          {!user && <Redirect to="/login" />}
          {user && <Scribble />}
        </Route>
        <Route path="/member/templates" exact>
          {!user && <Redirect to="/login" />}
          {user && <Templates />}
        </Route>
        <Route path="/member/history" exact>
          {!user && <Redirect to="/login" />}
          {user && <History />}
        </Route>
        <Route path="/member/settings" exact>
          {!user && <Redirect to="/login" />}
          {user && <Settings />}
        </Route>
        <Route path="/member/profile" exact>
          {!user && <Redirect to="/login" />}
          {user && <Profile />}
        </Route>
        <Route path="/member/subscription" exact>
          {!user && <Redirect to="/login" />}
          {user && <Subscription />}
        </Route>
        <Route path="/member/subscription/success" exact>
          {!user && <Redirect to="/login" />}
          {user && <SubscriptionsSuccess />}
        </Route>
        <Route path="/member/subscription/error" exact>
          {!user && <Redirect to="/login" />}
          {user && <SubscriptionsError />}
        </Route>
      </IonRouterOutlet>

      <IonTabBar slot="bottom" className="show-on-small-and-down">
        <IonTabButton tab="history" href="/member/history">
          <IonIcon icon={calendarOutline} />
          <IonLabel>{AppConfig.pageTitles.history}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="scribble" href="/member/templates">
          <IonIcon icon={pencilOutline} />
          <IonLabel>{AppConfig.pageTitles.home}</IonLabel>
        </IonTabButton>
        <IonTabButton tab="settings" href="/member/settings">
          <IonIcon icon={settingsOutline} />
          <IonLabel>{AppConfig.pageTitles.settings}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MemberTabs;
