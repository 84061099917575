import { getAuth } from '@firebase/auth';
import { deleteUser } from 'firebase/auth';
import { useState } from 'react';
import { AuthAction } from '../../../core/context/AuthContext';
import { useAuthContext } from './useAuthContext';
import { useHandleError } from './useHandleError';

export const useUserProfile = () => {
  const { dispatch } = useAuthContext();
  const { message } = useHandleError();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState('');

  const deleteCurrentUser = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const production = false;

    console.debug('useUserProfile.deleteCurrentUser', user);
    setError('');

    try {
      if (production) {
        await deleteUser(user!);

        if (dispatch) {
          dispatch({ type: AuthAction.DeleteUser });
        }
      }
    } catch (err: any) {
      setError(message(err));
      throw err;
    }
  };

  return { deleteUser: deleteCurrentUser };
};
