import { signOut } from 'firebase/auth';
import { auth } from '../../../config/firebase.config';
import { AuthAction } from '../../../core/context/AuthContext';
import { useAuthContext } from './useAuthContext';

export const useLogout = () => {
  const { dispatch } = useAuthContext();

  const logout = async () => {
    try {
      await signOut(auth);

      if (dispatch) {
        dispatch({ type: AuthAction.Logout });
      }
    } catch (error: any) {
      console.error(error.message);
    }
  };

  return { logout };
};
