import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from '@ionic/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSignInWithApple, useSignInWithGoogle, useSignInWithTwitter } from 'react-firebase-hooks/auth';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import ErrorText from '../../../../components/ErrorText';
import { AppConfig } from '../../../../config/app.config';
import { auth } from '../../../../config/firebase.config';
import { useAppContext } from '../../../../core/hooks/useAppContext';
import { useLoading } from '../../../../core/hooks/useLoading';
import logoGoogleIcon from '../../../../images/google.svg';
import { useLogin } from '../../hooks/useLogin';
import { useSignup } from '../../hooks/useSignup';
import { AuthProvider } from '../Login/AuthProvider';
import { SignupPage } from './Signup.styles';
import { useCheckout } from '../../../../core/hooks/useCheckout';
interface Props extends RouteComponentProps {}

const Signup: React.FC<Props> = ({ history }) => {
  const { error, signup } = useSignup();
  const { toggleLoading } = useLoading();
  const [signInWithApple, userApple, errorApple] = useSignInWithApple(auth);
  const [signInWithGoogle, userGoogle, errorGoogle] = useSignInWithGoogle(auth);
  const [signInWithTwitter, userTwitter, errorTwitter] = useSignInWithTwitter(auth);
  const [registeredUser, setRegisteredUser] = useState<any>(null);
  const { loginError, updateUser } = useLogin();
  const { isDesktop } = useAppContext();
  const { checkout, isProcessing } = useCheckout();
  
  const loginWithExternal = async (provider: AuthProvider) => {
    try {
      toggleLoading(true, 'Logging in...');
      switch (provider) {
        case AuthProvider.Apple:
          await signInWithApple();
          break;
        case AuthProvider.Google:
          await signInWithGoogle();
          break;
        case AuthProvider.Twitter:
          await signInWithTwitter();
          break;
      }
    } catch (err) {
      // TODO: handle error
      console.error(err);
    } finally {
      toggleLoading();
    }
  };
  const redirectToCheckout = async (priceId: any,user_id:any) => 
  {
    await checkout(user_id, priceId);
    toggleLoading(true, 'loading...');
  }

  useEffect(() => {
    if (userGoogle) {
      setRegisteredUser(userGoogle);
    } else if (userApple) {
      setRegisteredUser(userApple);
    } else if (userTwitter) {
      setRegisteredUser(userTwitter);
    }
  }, [userApple, userGoogle, userTwitter]);

  useEffect(() => {
    if (registeredUser) {
      updateUser(registeredUser.user);
      history.push(AppConfig.routes.member.home, { direction: 'none' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredUser]);

  const signupForm = useFormik({
    initialValues: { displayName: '', email: '', password: '' },
    validationSchema: Yup.object({
      displayName: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().min(10, 'Must be at least 10 characters').required('Password is required'),
    }),
    onSubmit: async (loginFormValues) => {
      toggleLoading(true, 'Signing up...');
      try {
        const user= await signup(loginFormValues.email, loginFormValues.password, loginFormValues.displayName);
        // check signup params
        const urlParams = new URLSearchParams(window.location.search)
        let price= urlParams.get('price')
        let price_id=null
        if(price=='29'){
          console.log('29',user);
          toggleLoading(true, 'loading...');
          // price_id='price_1LXJ4cBNiIJiawY4hVeDPUyu'
          price_id='price_1MGgNzBNiIJiawY4XrfTZFPW'
          
          redirectToCheckout(price_id,user.uid)
        }else if(price=='59'){
          toggleLoading(true, 'loading...');
          // price_id='price_1LUQbwBNiIJiawY4DppAYnJt'
          price_id='price_1M9WPnBNiIJiawY482SNrJXd'
          redirectToCheckout(price_id,user.uid)
        }else{
          history.push(AppConfig.routes.member.subscription, { direction: 'none' });
        }
      }  catch (err) {
        console.error(err); // TODO: handle error
      } finally {
        toggleLoading();
        
      }
    },
  });

  return (
    <SignupPage>
      <IonContent forceOverscroll>
        <IonGrid className="ion-no-padding">
          <IonRow>
        
            <IonCol size="auto" className="signup-form">
              <form noValidate onSubmit={signupForm.handleSubmit} className="page-centered max-form-width signup-form">
                <IonList lines="none" className="full-width">
                  <IonImg src="/assets/icon/logo.svg" className="logo-auth" />
                  <h2 className="ion-no-padding ion-no-margin ion-padding-horizontal">{AppConfig.pageTitles.signup}</h2>

                  <IonItem>
                    <IonLabel position="stacked" color="primary">
                      Name
                    </IonLabel>
                    <IonInput
                      name="displayName"
                      type="text"
                      spellCheck={false}
                      autocapitalize="off"
                      onIonChange={signupForm.handleChange}
                      onIonBlur={signupForm.handleBlur}
                      value={signupForm.values.displayName}
                      autocomplete="name"
                    ></IonInput>
                    {signupForm.touched.displayName && signupForm.errors.displayName ? (
                      <ErrorText message={signupForm.errors.displayName}></ErrorText>
                    ) : null}
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked" color="primary">
                      Email
                    </IonLabel>
                    <IonInput
                      id="email"
                      name="email"
                      type="email"
                      spellCheck={false}
                      autocapitalize="off"
                      onIonChange={signupForm.handleChange}
                      onIonBlur={signupForm.handleBlur}
                      value={signupForm.values.email}
                      autocomplete="email"
                    ></IonInput>
                    {signupForm.touched.email && signupForm.errors.email ? (
                      <ErrorText message={signupForm.errors.email}></ErrorText>
                    ) : null}
                  </IonItem>

                  <IonItem>
                    <IonLabel position="stacked" color="primary">
                      Password
                    </IonLabel>
                    <IonInput
                      id="password"
                      name="password"
                      type="password"
                      onIonChange={signupForm.handleChange}
                      onIonBlur={signupForm.handleBlur}
                      value={signupForm.values.password}
                      autocomplete="new-password"
                    ></IonInput>
                    {signupForm.touched.password && signupForm.errors.password ? (
                      <ErrorText message={signupForm.errors.password}></ErrorText>
                    ) : null}
                  </IonItem>
                </IonList>
                {error && <ErrorText message={error}></ErrorText>}
                <small className="ion-text-wrap ion-margin">
                  By clicking "Sign Up", you acknowledge that you have read, understood, and agree to ScribeSpace's{' '}
                  <Link to="/terms-and-conditions">{AppConfig.pageTitles.termsAndConditions}</Link>.
                </small>

                <IonRow className="ion-padding-horizontal full-width">
                  {/* <IonCol>
              <IonButton routerLink="/login" color="light" expand="block">
                Login
              </IonButton>
            </IonCol> */}
                  <IonCol>
                    <IonButton type="submit" expand="block">
                      Sign Up
                    </IonButton>
                  </IonCol>
                </IonRow>
                {/* <IonRow className="ion-padding-horizontal full-width">
            <IonCol>
              <IonButton routerLink="/login" color="light" expand="block">
                Login
              </IonButton>
            </IonCol>
          </IonRow> */}
                <span className="ion-margin-vertical">or</span>
                <IonRow>
                  {/* <IonCol>
              <IonButton onClick={() => loginWithExternal(AuthProvider.Apple)} color="dark">
                <IonIcon icon={logoApple}></IonIcon>&nbsp;Apple
              </IonButton>
            </IonCol> */}
                  <IonCol>
                    <IonButton onClick={() => loginWithExternal(AuthProvider.Google)} color="light">
                      <IonIcon icon={logoGoogleIcon}></IonIcon>&nbsp;Google
                    </IonButton>
                  </IonCol>
                  {/* <IonCol>
              <IonButton onClick={() => loginWithExternal(AuthProvider.Twitter)} color="twitter">
                <IonIcon icon={logoTwitter}></IonIcon>&nbsp;Twitter
              </IonButton>
            </IonCol> */}
                </IonRow>
                {(loginError || errorGoogle || errorTwitter || errorApple) && (
                  <ErrorText message={loginError || errorGoogle || errorTwitter || errorApple}></ErrorText>
                )}
                <div className="ion-margin">
                  Already a member?{' '}
                  <Link to="/login">
                    <b>Login</b>
                  </Link>
                </div>
              </form>
            </IonCol>
            {isDesktop && (
              <IonCol>
                <div className='splashScreenFinal'>
                  <div className='mainsignupcontent'>
                  <h3 className='h3Text'>Join over 70000+ leading brands, <br></br> agencies and content creators.</h3>
                  <img src="https://scribespace.ai/wp-content/uploads/2023/03/banner-proof-logos.png" className="signup-auth" />
                  </div>
                </div>
                {/* {<IonImg className="splashScreen" src="https://scribespace.ai/wp-content/uploads/2022/10/LoginSplashScreen.svg"></IonImg> } */}
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </SignupPage>
  );
};

export default Signup;
