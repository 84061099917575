export const useHandleError = () => {
  const message = (error: any): string => {
    if (error) {
      // if (error.code === 'auth/wrong-password') {
      //   return 'Wrong password';
      // }
      // if (error.code === 'auth/user-not-found') {
      //   return 'User not found';
      // }
      if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
        return 'Wrong email or password';
      }

      if (error.code === 'auth/invalid-email') {
        return 'Invalid email';
      }

      if (error.code === 'auth/email-already-in-use') {
        return 'Email already in use';
      }

      if (error.code === 'auth/weak-password') {
        return 'Weak password';
      }

      if (error.code === 'auth/too-many-requests') {
        return 'Too many requests';
      }

      if (error.code === 'auth/user-disabled') {
        return 'User disabled';
      }

      if (error.code === 'auth/requires-recent-login') {
        return 'Requires recent login';
      }

      if (error.code === 'auth/user-token-expired') {
        return 'User token expired';
      }

      if (error.code === 'auth/web-storage-unsupported') {
        return 'Web storage unsupported';
      }

      if (error.code === 'auth/argument-error') {
        return 'Argument error';
      }

      if (error.code === 'auth/app-deleted') {
        return 'App deleted';
      }

      if (error.code === 'auth/invalid-api-key') {
        return 'Invalid api key';
      }

      if (error.code === 'auth/network-request-failed') {
        return 'Network request failed';
      }

      if (error.code === 'auth/invalid-credential') {
        return 'Invalid credential';
      }

      if (error.code === 'auth/invalid-verification-code') {
        return 'Invalid verification code';
      }

      if (error.code === 'auth/invalid-verification-id') {
        return 'Invalid verification id';
      }
    }
    return '';
  };

  return { message };
};
