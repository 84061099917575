import { IonBackButton, IonButtons, IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { AppConfig } from '../../../config/app.config';
import { functions } from '../../../config/firebase.config';

import Price from '../../../core/domain/Price';
import Product from '../../../core/domain/Product';
import Subscription from '../../../core/domain/Subscription';
import { useCheckout } from '../../../core/hooks/useCheckout';
import { useFirebaseFunctions } from '../../../core/hooks/useFirebaseFunctions';
import { useLoading } from '../../../core/hooks/useLoading';
import { useSubscription } from '../../../core/hooks/useSubscription';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import ProductCard from '../ProductCard';

import { SubscriptionsPage } from './Subscriptions.styles';

const Subscriptions = () => {
  const pageTitle = 'Subscription';
  const { products } = useFirebaseFunctions();
  const { toggleLoading } = useLoading();

  const { user } = useAuthContext();
  const { checkout, isProcessing } = useCheckout();
  const [plans, setPlans] = useState<Array<Subscription>>([]);
  const { subscriptions } = useSubscription();

  useEffect(() => {
    toggleLoading(isProcessing, isProcessing ? 'checkout...' : ''); // TODO: update text and move to constants
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing]);

  const noSubscription = subscriptions && subscriptions.length === 0;

  useEffect(() => {
    const getProducts = async () => {
      if (noSubscription) {
        toggleLoading(true, 'loading...');
        const prods = await products();
        const plans = prods.map((p: any) => {
          return {
            price: { priceId: p.priceId, amount: p.price, unit_amount: p.unit_amount } as Price,
            product: {
              id: p.id,
              name: p.name,
              description: p.description,
              images: p.images,
              credits: p.credits || 0,
            } as Product,
          } as Subscription;
        });

        setPlans(plans);
        toggleLoading(false);
      }
    };

    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToCheckout = async (priceId: any) => await checkout(user.uid, priceId);

  const manageSubscription = async () => {
    toggleLoading(true);
    // had to update firebase.app().functions() to firebase.default.functions() and
    // removed the region from the functions call (from stripe firebase extension docs)
    try {
      const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
      const { data }: any = await functionRef({
        returnUrl: `${window.location.origin}${AppConfig.routes.member.subscriptionSuccess}`,
      });
      window.location.assign(data.url);
    } catch (error: any) {
      // REF: https://firebase.google.com/docs/functions/callable#handle_errors_on_the_client
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.error(`error: ${code} ${message} ${details}\n${error}`);
      window.location.assign(`${window.location.origin}${AppConfig.routes.member.subscriptionError}`);
    }
  };

  return (
    <SubscriptionsPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={AppConfig.routes.member.settings} />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="subscription-container ion-padding-top">
          {subscriptions &&
            subscriptions.map((subscription) => (
              <ProductCard
                key={subscription.product.id}
                product={subscription.product}
                price={subscription.price}
                buttonText="Manage Subscription"
                productOnClick={manageSubscription}
              ></ProductCard>
            ))}

          {noSubscription &&
            plans.map((subscription: Subscription) => (
              <ProductCard
                key={subscription.product.id}
                product={subscription.product}
                buttonText="Subscribe"
                productOnClick={() => redirectToCheckout(subscription.price.priceId)}
                price={subscription.price}
              ></ProductCard>
            ))}
        </div>
      </IonContent>
    </SubscriptionsPage>
  );
};

export default Subscriptions;
