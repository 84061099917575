import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const SignupPage = styled(IonPage)`
  ion-select,
  input,
  textarea {
    padding-left: 8px !important;
  },

  ion-img.splashScreen.md {object-fit: cover;}
  .splashScreenFinal{
    background:#6320EE !important;
  } 

  .mainsignupcontent{
    display:flex !important;
    justify-content:center !important;
    align-items:center !important;
    flex-direction:column;
    height:100vh !important;
  } 
  .h3Text{
    font-size: 24px !important;
    color: #fff !important;
    font-weight: 700 !important;
    line-height: 34px !important;
    text-align: center !important;
  }
  .signup-auth{
    opacity: 0.5 !important;
    width: 100% !important;
    display: flex;
    max-width: 40% !important;
    justify-content: center !important;
    margin-top:10px !important;
    margin-left: 3% !important;
  }
`;
