import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useState } from 'react';
import { auth } from '../../../config/firebase.config';
import { AuthAction } from '../../../core/context/AuthContext';
import { useAuthContext } from './useAuthContext';
import { useHandleError } from './useHandleError';

export const useSignup = () => {
  const [error, setError] = useState('');
  const { dispatch } = useAuthContext();
  const { message } = useHandleError();

  const signup = async (email: string, password: string, displayName?: string) => {
    setError('');

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      if (dispatch) {
        dispatch({ type: AuthAction.Login, payload: userCredential.user });
        if (displayName) updateProfile(userCredential.user, { displayName });
      }
      return userCredential.user
    } catch (err) {
      setError(message(err));
      throw err;
    }
  };

  return { error, signup };
};
