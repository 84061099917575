import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Link } from 'react-router-dom';
import { AppConfig } from '../../../config/app.config';
import { useAuthContext } from '../../auth/hooks/useAuthContext';
import { useScribbleHistory } from '../../auth/hooks/useScribbleHistory';
import { HistoryPage } from './History.styles';
import HistoryList from './HistoryList';

const History = () => {
  const pageTitle = AppConfig.pageTitles.history;
  const { user } = useAuthContext();
  const { historyItems, loading } = useScribbleHistory(user.uid);

  return (
    <HistoryPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
          {loading && <IonProgressBar type="indeterminate" color="secondary"></IonProgressBar>}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
        {historyItems && historyItems.docs.length <= 0 && (
          <>
            <IonItem lines="none">
              <IonLabel className="ion-text-center ion-margin ion-padding">nothing here 😔</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel className="ion-text-center ion-margin ion-padding">
                <Link to={AppConfig.routes.member.templates}>go Scribble something...</Link>
              </IonLabel>
            </IonItem>
          </>
        )}
        {historyItems && <HistoryList history={historyItems}></HistoryList>}
      </IonContent>
    </HistoryPage>
  );
};

export default History;
