import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const TemplatesPage = styled(IonPage)`
  .align-end {
    display: flex;
    justify-content: end;
  }
  .hideGrid{
    display:none;
  }

  .main-slide{
    // width:178px!important;
  }

  .template-list {
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    column-count: 3;
    justify-content: center;
  }

  .template-list-item {
    width: 380px;
    justify-content: flex-start;
    margin-top: 8px;
  }

  .template-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .template-img {
    height: 80px;
    width: auto;
    max-width: 100px;
    border-radius: 10px;
    /* background-color: #f5f5f5; */
    border-color: #bfbfbf;
    border-width: 1px;
  }

  .pop-template-card {
    width: 140px;
    min-height: 162px;
    height: 100% !important;
  }
  .wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
    display: flex;
    padding: 1rem;
    border: none;
    flex-direction: column-reverse;
}
  }
  
.wrapper-class {
  padding: 1rem;
  border: none;
  padding: 6px 30px 0px 60px !important;
}
  .editor-class {
    padding: 1rem;
    border: 1px solid #ccc;
    
  }
  .public-DraftStyleDefault-ltr {
    direction: ltr;
    text-align: left;
    height: 50vh;
}
  .toolbar-class {
    border: 1px solid #ccc;
    margin-top: 10px;
  }
  .btnSave{
    padding-left:10px;
  }
  .copy-btn{
        filter: opacity(0.5);
  }
  .wrapper-class {
    padding: 1rem;
    border: none;
}
  .buttoncs{
    margin-top:10px;
  }
  .template-popular-img {
    max-width: 60px;
    height: 60px;
    border-radius: 10px;
    /* background-color: #f5f5f5; */
  }

  .swiper-slide-actives{
    border:1px solid #6320EE!important;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  .content-grid{
    border-top:1px solid #E5E5E5;
    height: 100vh;
  }
  .content-full{
    height:100% !important;
  }
  .grid-one{
    border-right:1px solid #E5E5E5;
    height: 100% !important;
  }
  h3 {
    font-size: 1rem;
    font-weight: 600;
  }

  .popular-container {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    font-weight: 600;
  }
  @media only screen and (max-width: 1024px) {
  
    .popular-templates-swiper {
      padding-bottom: 40px;
    }
    .swiper-pagination {
      display: none;
    }
  }



  @media only screen and (min-width: 1024px) {
  
    .popular-templates-swiper {
      padding-bottom: 40px;
    }
  }
  '
  
`;
