import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const ScribblePage = styled(IonPage)`
  .input-padding {
    padding: 8px;
  }

  .content-grid{
    border-top:1px solid #E5E5E5;
  }
  .content-full{
    height:100% !important;
  }
  .grid-one{
    border-right:1px solid #E5E5E5;
    height: 100vh !important;
    max-height:100vh !important;
    overflow-y:auto !important;
  }
  h3 {
    font-size: 1rem;
    font-weight: 600;
  }

  .scribble-parent-container {
    display: flex !important;
    flex-direction:column !important;
  }

  
  .scribeButton{
   
    width:100% !important;
    margin:0 !important;
  }

  @media (min-width:300px) and (max-width:700px) {
    .grid-two{
      display:none;
    }
  }
  @media only screen and (min-width: 1024px) {
    .scribble-parent-container {
      display: flex;
    }

    .scribble-container {
      width: 100%;
      max-width: 40vw;
    }

    .scribble-history {
      width: 100%;
      max-width: 40vw;
    }

    .scribble-btn {
      right: 0;
      max-width: 200px;
    }
  }
`;
