import { loadStripe } from '@stripe/stripe-js';
import { addDoc, collection, FirestoreError, onSnapshot } from 'firebase/firestore';
import { useState } from 'react';
import { db } from '../../config/firebase.config';

export const useCheckout = () => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const onError = (error: FirestoreError) => {
    console.error(error); // TODO: handle error
  };

  const onCompletion = () => setIsProcessing(false);

  const checkout = async (userId: string, priceId: string) => {
    console.debug('useCheckout.checkout', userId, priceId);
    setIsProcessing(true);
    const collectionRef = collection(db, `customers/${userId}/checkout_sessions`);
    try {
      const checkoutSessionDoc = await addDoc(collectionRef, {
        price: priceId,
        success_url: `${window.location.origin}/member/subscription`,
        cancel_url: `${window.location.origin}/member/subscription`,
      });
      const unSubscribe = onSnapshot(
        checkoutSessionDoc,
        async (snapshot) => {
          console.debug('subscribeToProduct: snapshot', snapshot.data());
          const { sessionId }: any = snapshot.data();
          if (sessionId) {
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
            const error = await stripe?.redirectToCheckout({ sessionId });
            if (error) {
              console.error(error); // TODO: handle error
            }
            unSubscribe(); // unsubscribe from the snapshot after redirecting to checkout
          }
        },
        onError,
        onCompletion
      );
    } catch (error) {
      console.error('Error adding document: ', error); // TODO: handle error
    }
  };

  return { checkout, isProcessing };
};
