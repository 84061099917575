import { collection, getFirestore, query, where } from 'firebase/firestore';
import { firebaseApp } from '../../config/firebase.config';

const subscriptionsCollectionRef = (userId: string) =>
  query(
    collection(getFirestore(firebaseApp), `customers/${userId}/subscriptions`),
    where('status', 'in', ['trialing', 'active'])
  );

export default subscriptionsCollectionRef;
