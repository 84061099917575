import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { AppConfig } from '../../../config/app.config';

type Props = { dismiss: () => void };

const PrivacyPolicy: React.FC<Props> = ({ dismiss }) => {
  const pageTitle = AppConfig.pageTitles.privacyPolicy;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="ion-padding">Your Privacy is important to us, read our full Privacy policy here: <a target="_blank" href="https://scribespace.ai/privacy-policy/">Learn More.</a></div>
      </IonContent>
    </IonPage>
  );
};

export default PrivacyPolicy;
