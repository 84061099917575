import { IonButtons, IonContent, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import { AppConfig } from '../../../config/app.config';
import { TemplateAdminPage } from './TemplateAdmin.styles';

const TemplateAdmin = () => {
  const pageTitle = `Admin ${AppConfig.pageTitles.templates}`;

  return (
    <TemplateAdminPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
      </IonContent>
    </TemplateAdminPage>
  );
};

export default TemplateAdmin;
