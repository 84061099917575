import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from '@ionic/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSignInWithApple, useSignInWithGoogle, useSignInWithTwitter } from 'react-firebase-hooks/auth';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ErrorText from '../../../../components/ErrorText';
import { AppConfig } from '../../../../config/app.config';
import { auth } from '../../../../config/firebase.config';
import { useLoading } from '../../../../core/hooks/useLoading';
import logoGoogleIcon from '../../../../images/google.svg';
import { useLogin } from '../../hooks/useLogin';
import { AuthProvider } from './AuthProvider';
import { LoginPage } from './Login.styles';

const Login: React.FC = () => {
  // const pageTitle = pageTitles.login; // TODO: get from AppContext or global config
  const { loginWithEmailPassword, loginError, updateUser } = useLogin();
  const history = useHistory();
  const { toggleLoading } = useLoading();
  const [signInWithApple, userApple, errorApple] = useSignInWithApple(auth);
  const [signInWithGoogle, userGoogle, errorGoogle] = useSignInWithGoogle(auth);
  const [signInWithTwitter, userTwitter, errorTwitter] = useSignInWithTwitter(auth);
  const [registeredUser, setRegisteredUser] = useState<any>({});

  const loginForm = useFormik({
    // initialValues: { email: AppConfig.testUser.email, password: AppConfig.testUser.password },
    initialValues: { email: '', password: '' },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().min(10, 'Must be at least 10 characters').required('Password is required'),
    }),
    onSubmit: async (loginFormValues) => {
      try {
        toggleLoading(true, 'Logging in...');
        await loginWithEmailPassword(loginFormValues.email, loginFormValues.password);
        history.push(AppConfig.routes.member.home, { direction: 'none' });
      } catch (err) {
        // TODO: handle error
        console.error(err);
      } finally {
        toggleLoading();
      }
    },
  });

  const loginWithExternal = async (provider: AuthProvider) => {
    try {
      toggleLoading(true, 'Logging in...');
      switch (provider) {
        case AuthProvider.Apple:
          await signInWithApple();
          break;
        case AuthProvider.Google:
          await signInWithGoogle();
          break;
        case AuthProvider.Twitter:
          await signInWithTwitter();
          break;
      }
    } catch (err) {
      // TODO: handle error
      console.error(err);
    } finally {
      toggleLoading();
    }
  };

  useEffect(() => {
    if (userGoogle) {
      setRegisteredUser(userGoogle);
    } else if (userApple) {
      setRegisteredUser(userApple);
    } else if (userTwitter) {
      setRegisteredUser(userTwitter);
    }
  }, [userApple, userGoogle, userTwitter]);

  useEffect(() => {
    if (registeredUser) {
      updateUser(registeredUser.user);
      history.push(AppConfig.routes.member.home, { direction: 'none' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredUser]);

  return (
    <LoginPage>
      <IonContent fullscreen>
        <form noValidate onSubmit={loginForm.handleSubmit} className="page-centered max-form-width">
          <IonList lines="none" className="full-width">
            <IonImg src="/assets/icon/logo.svg" className="logo-auth" />
            <h2 className="ion-no-padding ion-no-margin ion-padding-horizontal">{AppConfig.pageTitles.login}</h2>
            <IonItem>
              <IonLabel position="stacked" color="primary">
                Email
              </IonLabel>

              <IonInput
                id="email"
                name="email"
                type="email"
                spellCheck={false}
                autocapitalize="off"
                onIonChange={loginForm.handleChange}
                onIonBlur={loginForm.handleBlur}
                value={loginForm.values.email}
                autocomplete="username"
              ></IonInput>
              {/* <IonIcon name={mailOutline} slot="end" color="danger"></IonIcon> */}
              {loginForm.touched.email && loginForm.errors.email ? (
                <ErrorText message={loginForm.errors.email}></ErrorText>
              ) : null}
            </IonItem>

            <IonItem>
              <IonLabel position="stacked" color="primary">
                Password
              </IonLabel>
              <IonInput
                id="password"
                name="password"
                type="password"
                onIonChange={loginForm.handleChange}
                onIonBlur={loginForm.handleBlur}
                value={loginForm.values.password}
                autocomplete="current-password"
              ></IonInput>
              {/* <IonIcon name={lockClosedOutline} slot="end" color="danger"></IonIcon> */}
              {loginForm.touched.password && loginForm.errors.password ? (
                <ErrorText message={loginForm.errors.password}></ErrorText>
              ) : null}
            </IonItem>
          </IonList>
          {(loginError || errorGoogle || errorTwitter || errorApple) && (
            <ErrorText message={loginError || errorGoogle || errorTwitter || errorApple}></ErrorText>
          )}
          <IonRow>
            <IonCol className="ion-padding-vertical">
              <Link to={AppConfig.routes.public.forgotPassword}>
                <b>{AppConfig.pageTitles.forgotPassword}?</b>
              </Link>
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-horizontal full-width">
            {/* <IonCol>
              <IonButton routerLink="/signup" color="light" expand="block">
                Signup
              </IonButton>
            </IonCol> */}
            <IonCol>
              <IonButton type="submit" expand="block">
                Login
              </IonButton>
            </IonCol>
          </IonRow>
          <span className="ion-margin-vertical">or Login with</span>
          <IonRow>
            {/* <IonCol>
              <IonButton onClick={() => loginWithExternal(AuthProvider.Apple)} color="dark">
                <IonIcon icon={logoApple}></IonIcon>&nbsp;Apple
              </IonButton>
            </IonCol> */}
            <IonCol>
              <IonButton onClick={() => loginWithExternal(AuthProvider.Google)} color="light">
                <IonIcon icon={logoGoogleIcon}></IonIcon>&nbsp;Google
              </IonButton>
            </IonCol>
            {/* <IonCol>
              <IonButton onClick={() => loginWithExternal(AuthProvider.Twitter)} color="twitter">
                <IonIcon icon={logoTwitter}></IonIcon>&nbsp;Twitter
              </IonButton>
            </IonCol> */}
          </IonRow>
          <div className="ion-margin-top">
            New to ScribeSpaceAI?{' '}
            <Link to="/signup">
              <b>Signup</b>
            </Link>
          </div>
          <IonRow className="ion-padding-horizontal full-width">
            {/* <IonCol>
              <IonButton routerLink="/signup" color="light" expand="block">
                Signup
              </IonButton>
            </IonCol> */}
            {/* <IonCol>
              <IonButton type="submit" expand="block">
                Login
              </IonButton>
            </IonCol> */}
          </IonRow>
        </form>
      </IonContent>
    </LoginPage>
  );
};

export default Login;
