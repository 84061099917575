import { useEffect } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useAuthContext } from '../../modules/auth/hooks/useAuthContext';
import { AppAction } from '../context/AppContext';
import Plan from '../domain/Plan';
import Price from '../domain/Price';
import Product from '../domain/Product';
import Subscription from '../domain/Subscription';
import subscriptionsCollectionRef from '../firebase/subscriptions-collectionRef';
import { useAppContext } from './useAppContext';

export const useSubscription = () => {
  const { user } = useAuthContext();
  const { dispatch, subscriptions } = useAppContext();
  const [subscriptionCollection] = useCollection(subscriptionsCollectionRef(user.uid));

  useEffect(() => {
    function createPriceObj(subscriptionData: any): Price {
      const priceObj = subscriptionData?.items.filter((item: any) => item.plan.active)[0].price;

      return {
        amount: priceObj?.unit_amount / 100,
        priceId: priceObj?.id,
        unit_amount: priceObj?.unit_amount,
      };
    }

    function createProductObj(subscriptionData: any): Product {
      const productObj = subscriptionData?.items.filter((item: any) => item.plan.active)[0].price.product;

      return {
        id: productObj.id,

        name: productObj.name,
        description: productObj.description,
        credits: productObj.metadata?.credits || 0,

        images: productObj?.images,
      };
    }

    function createPlanObj(subscriptionData: any): Plan {
      return {
        status: subscriptionData?.status,

        cancel_at_period_end: subscriptionData?.cancel_at_period_end,
        current_period_start: new Date(subscriptionData?.current_period_start.seconds * 1000),
        current_period_end: new Date(subscriptionData?.current_period_end.seconds * 1000),

        trial_start: subscriptionData?.trial_start ? new Date(subscriptionData?.trial_start.seconds * 1000) : undefined,
        trial_end: subscriptionData?.trial_end ? new Date(subscriptionData?.trial_end.seconds * 1000) : undefined,
      };
    }

    function createSubScriptionObj(subscriptionData: any): Subscription {
      const product: Product = createProductObj(subscriptionData);
      const price: Price = createPriceObj(subscriptionData);
      const plan: Plan = createPlanObj(subscriptionData);

      return { product, price, plan };
    }

    const updateSubscription = () => {
      if (!subscriptionCollection) return;

      const subs: Array<Subscription> = [];
      subscriptionCollection?.docs.forEach((doc) => {
        const subscriptionData = doc.data();
        // console.debug('updateSubscription', subscriptionData.items[0].price.product.metadata.credits);
        const sub = createSubScriptionObj(subscriptionData);
        if (sub) {
          subs.push(sub);
        }
      });
      if (subs.length > 0 && dispatch) {
        dispatch({ type: AppAction.ManageSubscriptions, payload: subs });
      }
    };

    updateSubscription();
  }, [subscriptionCollection, dispatch]);

  return { subscriptions };
};
