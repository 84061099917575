import {
  IonAvatar,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonMenuButton,
  IonModal,
  IonNote,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { cardOutline, documentLockOutline, documentTextOutline, moonOutline, personOutline } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppConfig } from '../../config/app.config';
import { useAppTheme } from '../../core/hooks/useAppTheme';
import { useSubscription } from '../../core/hooks/useSubscription';
import { useAuthContext } from '../auth/hooks/useAuthContext';
import { useScribbleHistory } from '../auth/hooks/useScribbleHistory';
import TermsAndConditions from '../legal/TermsAndConditions';
import { SettingsPage } from './Settings.styles';

const Settings: React.FC = () => {
  const pageTitle = AppConfig.pageTitles.settings;
  const { user } = useAuthContext();
  const { subscriptions } = useSubscription();
  const { creditsUsed } = useScribbleHistory(user.uid);
  const history = useHistory();
  const { theme, toggleTheme } = useAppTheme();
  const [showModal, setShowModal] = useState(false);

  return (
    <SettingsPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList lines="full" className="ion-no-padding">
          <IonItemGroup>
            <div className="spacer"></div>
            <IonItem onClick={() => history.push(AppConfig.routes.member.profile)} detail>
              {!user.photoURL && <IonIcon icon={personOutline} color="secondary" className="ion-padding-end"></IonIcon>}
              {user.photoURL && (
                <IonAvatar slot="start">
                  <img src={user.photoURL} alt="avatar" />
                </IonAvatar>
              )}
              <IonLabel className="ion-padding-vertical">
                <div className="display-name">{user.displayName}</div>
              </IonLabel>
            </IonItem>
          </IonItemGroup>

          {subscriptions &&
            subscriptions.map((subscription) => (
              <IonItemGroup
                onClick={() => history.push(AppConfig.routes.member.subscription)}
                key={subscription.product.id}
              >
                <div className="spacer"></div>
                <IonItem lines="none">
                  <IonLabel>
                    <h1 className="ion-text-wrap">{subscription.product.name}</h1>
                    <div>
                      {!subscription.plan?.cancel_at_period_end && (
                        <p className="subscription-renew">
                          Renews {subscription.plan?.current_period_end.toLocaleDateString()}
                        </p>
                      )}
                      {subscription.plan?.cancel_at_period_end && (
                        <p className="subscription-expiring">
                          Expiring {subscription.plan?.current_period_end.toLocaleDateString()}
                        </p>
                      )}
                    </div>
                  </IonLabel>
                  <IonNote slot="end">${subscription.price.amount}/month</IonNote>
                </IonItem>
                <IonItem detail>
                  <IonLabel>Credits Used</IonLabel>
                  <span slot="end" className="credits-used">
                    <IonIcon icon={cardOutline} size="medium"></IonIcon>
                    &nbsp;{creditsUsed()}/{subscription.product.credits}
                  </span>
                </IonItem>
              </IonItemGroup>
            ))}

          {subscriptions?.length === 0 && (
            <IonItemGroup onClick={() => history.push(AppConfig.routes.member.subscription)}>
              <div className="spacer"></div>
              <IonItem lines="none">
                <IonLabel>You don't have an active subscription. </IonLabel>
              </IonItem>
              <IonItem detail>
                <span slot="end" className="all-plans">
                  See All Plans
                </span>
              </IonItem>
            </IonItemGroup>
          )}

          {/* <IonItemGroup>
            <div className="spacer"></div>
            <IonItem>
              <IonIcon icon={moonOutline} className="ion-padding-end"></IonIcon>
              <IonLabel>{AppConfig.text.darkMode}</IonLabel>
              <IonToggle checked={theme === AppConfig.theme.dark} onClick={() => toggleTheme()} />
            </IonItem>
          </IonItemGroup> */}

          <IonItemGroup>
            <div className="spacer"></div>
            <IonItem onClick={() => history.push('/privacy-policy')} detail>
              <IonIcon icon={documentLockOutline} color="primary" className="ion-padding-end"></IonIcon>
              <IonLabel>{AppConfig.pageTitles.privacyPolicy}</IonLabel>
            </IonItem>
            <IonItem onClick={() => history.push('/terms-and-conditions')} detail>
              <IonIcon icon={documentTextOutline} color="primary" className="ion-padding-end"></IonIcon>
              <IonLabel>{AppConfig.pageTitles.termsAndConditions}</IonLabel>
            </IonItem>
          </IonItemGroup>
        </IonList>

        <div className="spacer"></div>

        {/* <div className="ion-text-center ion-margin-top openai-logo-container">
          <div>
            <SvgImg src={OpenAILogo} />
          </div>
          <div>
            <small>
              Powered by{' '}
              <a href="https://openai.com/about/" target="_blank" rel="noreferrer">
                <span className="openai">OpenAI</span>
              </a>
            </small>
          </div>
        </div> */}

        <div className="ion-text-center ion-margin-top">
          <small>
            &copy; {AppConfig.appName} {new Date().getUTCFullYear()}
          </small>
        </div>

        <IonModal isOpen={showModal} canDismiss={true}>
          <TermsAndConditions dismiss={() => setShowModal(false)}></TermsAndConditions>
        </IonModal>
      </IonContent>
    </SettingsPage>
  );
};

export default Settings;
