import { httpsCallable } from 'firebase/functions';
import { functions } from '../../config/firebase.config';
import Product from '../domain/Product';

export type ScribbleRequest = {
  text?: string;
  template?: string | (string | null)[];
  prompts: { key: string; value: string }[];
  model?: string;
  tokens?: number;
  checkCredit:boolean
};

export const useFirebaseFunctions = () => {
  const scribble = async (template = 'default', prompts: Array<any> = [],checkCredit:any) => {
    try {
      const scribbleCloud = httpsCallable(functions, 'scribble');
      const result = await scribbleCloud({ template, prompts, checkCredit });
      return result;
    } catch (error: any) {
      throw error;
    }
  };

  const products = async () => {
    const productsCloud = httpsCallable(functions, 'products');
    const response = await productsCloud();
    const list: any = response.data;
    const activeProds = list.products.filter((p: any) => p.active);

    const prods = activeProds.map((prod: any) => {
      const price = list.prices.filter((price: any) => price.product === prod.id && price.active)[0];
      return {
        id: prod.id,
        name: prod.name,
        description: prod.description,
        credits: prod.metadata?.credits || 0,
        images: prod.images,

        unit_amount: price.unit_amount,
        price: price.unit_amount / 100,
        priceId: price.id,
      } as Product;
    });

    const productAmountCompare = (prodA: any, prodB: any) =>
      prodA.unit_amount > prodB.unit_amount ? 1 : prodA.unit_amount < prodB.unit_amount ? -1 : 0;

    return prods.sort(productAmountCompare) as Array<Product>;
  };

  return { products, scribble };
};
