import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { AppConfig } from '../../../config/app.config';
import { SubscriptionsSuccessPage } from './SubscriptionsSuccess.styles';

const SubscriptionsSuccess = () => {
  const pageTitle = 'Subscription';

  return (
    <SubscriptionsSuccessPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={AppConfig.routes.member.settings} />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <h1 className="ion-padding-bottom ion-margin-bottom">
          Welcome to Scribe Space! Your subscription has been successful!
        </h1>
        <IonButton color="primary" href="/member/templates">
          Let's get started!
        </IonButton>
      </IonContent>
    </SubscriptionsSuccessPage>
  );
};

export default SubscriptionsSuccess;
