export const AppConfig = {
  appName: 'Scribe Space AI',
  pageTitles: {
    login: 'Login',
    signup: 'Signup',
    profile: 'Profile',
    forgotPassword: 'Forgot Password',
    logout: 'Logout',
    deleteAccount: 'Delete Account',

    home: 'Scribe',
    history: 'History',
    settings: 'Settings',
    templates: 'Templates',
    products: 'Products',

    privacyPolicy: 'Privacy Policy',
    termsAndConditions: 'Terms & Conditions',
  },
  messages: {
    copiedToClipboard: 'Copied to clipboard',
    profileUpdated: 'Profile updated',
    error: {
      default: 'Something went wrong',
    },
    insufficientCredits: {
      header: 'Insufficient credits',
      message: 'You do not have enough credits to perform this action. Please upgrade your subscription.',
    },
    resetPassword: {
      header: 'Reset Password',
      success: 'If you have an account, you will receive an email with instructions on how to reset your password.',
      error: 'Error resetting password',
    },
  },
  delay: {
    toastDuration: 1500,
  },
  theme: {
    dark: 'dark-theme',
    light: 'light-theme',
  },
  text: {
    darkMode: 'Dark Mode',
    credits: 'Cr',
    tokens: 'Tokens',
  },
  storageKeys: {
    theme: 'THEME',
  },
  openai: {
    tokens: [5, 10, 25, 50, 100],
  },
  routes: {
    public: {
      login: '/login',
      signup: '/signup',
      forgotPassword: '/forgot-password',

      privacyPolicy: '/privacy-policy',
      termsAndConditions: '/terms-and-conditions',
    },
    member: {
      home: '/member/templates',
      templates: '/member/templates',
      scribble: '/member/scribble',
      history: '/member/history',

      profile: '/member/profile',
      subscription: '/member/subscription',
      subscriptionSuccess: '/member/subscription/success',
      subscriptionError: '/member/subscription/error',

      settings: '/member/settings',
    },
  },
};
