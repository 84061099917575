import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const SubscriptionsPage = styled(IonPage)`
  .subscription-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;

    justify-content: space-evenly;
    align-items: stretch;
  }

  .product-card {
    width: 360px;
  }
`;
