import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import { closeCircleOutline, logOutOutline } from 'ionicons/icons';
import { AppConfig } from '../../../../config/app.config';
import { useLoading } from '../../../../core/hooks/useLoading';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useLogout } from '../../hooks/useLogout';
import { useUserProfile } from '../../hooks/useUserProfile';
import { ProfilePage } from './Profile.styles';

const Profile = () => {
  const pageTitle = AppConfig.pageTitles.profile; // TODO: get from AppContext
  const { user } = useAuthContext();
  const { toggleLoading } = useLoading();
  const { deleteUser } = useUserProfile();
  const altProfileImage = `https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y`;
  const { logout } = useLogout();

  const handleLogout = async () => {
    toggleLoading(true, 'Logging out...');
    try {
      await logout();
    } catch (error) {
      console.error(error); // TODO: handle error
    } finally {
      toggleLoading();
    }
  };

  const [presentAlertForDeleteUser] = useIonAlert();
  const deleteAccount = async () => {
    return presentAlertForDeleteUser({
      header: 'Are you sure you want to delete your account?',
      message:
        'This action cannot be undone and you will loose all remaining credits. All your data will be permanently deleted and you will no longer be able to access this app.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            console.debug('Confirm Okay');
            // HACK: uncomment when deleteUser is working and for production
            deleteUser();
          },
        },
      ],
    });
  };

  return (
    <ProfilePage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={AppConfig.routes.member.settings} />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList lines="full">
          <IonItemGroup>
            <div className="profileHeaderContainer ion-padding-vertical">
              <img src={user.photoURL || altProfileImage} className="avatar" alt="avatar" />
              {user.displayName && <h1 className="ion-no-margin ion-margin-top display-name">{user.displayName}</h1>}
              <p className="ion-no-margin email ion-margin-top">{user.email}</p>
            </div>
          </IonItemGroup>
          <IonItemGroup>
            <div className="spacer"></div>
            <IonItem onClick={handleLogout}>
              <IonIcon icon={logOutOutline} color="secondary" className="ion-padding-end"></IonIcon>
              <IonLabel>{AppConfig.pageTitles.logout}</IonLabel>
            </IonItem>
          </IonItemGroup>

          <IonItemGroup>
            <div className="spacer"></div>
            <IonItem onClick={deleteAccount}>
              <IonIcon icon={closeCircleOutline} color="danger" className="ion-padding-end"></IonIcon>
              <IonLabel>{AppConfig.pageTitles.deleteAccount}</IonLabel>
            </IonItem>
          </IonItemGroup>
        </IonList>
        <div className="spacer"></div>
      </IonContent>
    </ProfilePage>
  );
};

export default Profile;
