// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions,connectFunctionsEmulator  } from 'firebase/functions';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCikEGc_hv4i3ufppJeq6W5tv6VBZHI2FU',
  authDomain: 'scribe-space-ai.firebaseapp.com',
  projectId: 'scribe-space-ai',
  storageBucket: 'scribe-space-ai.appspot.com',
  messagingSenderId: '6992963442',
  appId: '1:6992963442:web:2bdddb3ed978c77bcadfeb',
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAtoTyY24wfWohqU1y9vwnYmG1aWXQSnSc",
//   authDomain: "scribespace-dev.firebaseapp.com",
//   databaseURL: "https://scribespace-dev-default-rtdb.firebaseio.com",
//   projectId: "scribespace-dev",
//   storageBucket: "scribespace-dev.appspot.com",
//   messagingSenderId: "906527986519",
//   appId: "1:906527986519:web:a8f900625e699442cae412",
//   measurementId: "G-WKLK0G5BKE"
// };

const firebaseApp = initializeApp(firebaseConfig)
const functions = getFunctions(firebaseApp)

// connectFunctionsEmulator(functions, "localhost", 5100)

// Initialize Firebase
// const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const auth = getAuth(firebaseApp);

const db = getFirestore(firebaseApp);

// const functions = getFunctions(firebaseApp);

// connectFunctionsEmulator(functions, 'localhost', 5000);
// connectAuthEmulator(auth, 'http://localhost:5001');

export { auth, db, functions, firebaseApp };
