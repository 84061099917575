import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { AppConfig } from '../../../config/app.config';

type Props = { dismiss: () => void };

const TermsAndConditions: React.FC<Props> = ({ dismiss }) => {
  const pageTitle = AppConfig.pageTitles.termsAndConditions;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="ion-padding">If you wish to read our full Terms and Conditions, please view our detailed policies <a target="_blank" href="https://scribespace.ai/terms-and-conditions/">Here.</a></div>
      </IonContent>
    </IonPage>
  );
};

export default TermsAndConditions;
