import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonRow,
  IonSearchbar,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useHistory } from "react-router-dom";
import { AppConfig } from "../../../config/app.config";
import templateCollectionRef from "../../../core/firebase/template-collectionRef";
import { useLoading } from "../../../core/hooks/useLoading";
import Scribble from "../Scribble";
// import {Editor, EditorState} from 'draft-js'
import { TemplatesPage } from "./Templates.styles";
// import Editor from "../Editor/editor";

const Templates = ({ ...props }): JSX.Element => {
  const [convertedText, setConvertedText] = useState("Some default content");

  // const [editorState, setEditorState] = useState(()=> EditorState.createEmpty())

  // const [convertedContent, setConvertedContent] = useState(null);
  // const handleEditorChange = (state) => {
  //   setEditorState(state);
  //   convertContentToHTML();
  // };
  // const convertContentToHTML = () => {
  //   let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
  //   setConvertedContent(currentContentAsHTML);
  // };
  // const createMarkup = (html) => {
  //   return {
  //     __html: DOMPurify.sanitize(html)
  //   };
  // };

  const pageTitle = "Templates";
  const [value, loading] = useCollection(templateCollectionRef());
  const [id, setid] = useState("");
  const [name, setname] = useState("");
  const history = useHistory();
  const { toggleLoading } = useLoading();
  const altImage = "https://via.placeholder.com/400x400.png/09f/fff";
  const [searchText, setSearchText] = useState("");


  // const [select,selected] = useState("Grammer Improver")

  useEffect(() => {
    toggleLoading(loading, loading ? "loading templates..." : "");
    localStorage.removeItem("CapacitorStorage.THEME");
    // TODO: handle dependency error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  // REF: https://swiperjs.com/swiper-api
  const sliderOptions = {
    // slidesPerView: 2.1,
    // spaceBetween: 10,
    breakpoints: {
   
      // when window width is >= 640px
      1024: {
        slidesPerView: 7,
        spaceBetween: 20
      },
      1280: {
        slidesPerView: 7,
        spaceBetween: 5
      },
      1366:{
        slidesPerView: 7,
        spaceBetween: 5
      },
      1600: {
        slidesPerView: 8,
        spaceBetween: 4
      },
      1920: {
        slidesPerView: 9,
        spaceBetween: 2
      }
      
    },
  };

  const searchTemplates = (doc: any) =>
    doc.data().name.toString().toLowerCase().includes(searchText.toLowerCase());

  const filteredTemplates = value ? value.docs.filter(searchTemplates) : [];

  const redirectToScribble = (doc: any) => {
    // document.getElementById("showGrid").style.display = 'block';
    
    const activeSlide = document.getElementById(doc.data().name) as HTMLDivElement | null;
    activeSlide?.classList.add('swiper-slide-actives')
    const showTemplate = document.getElementById("showTemplate") as HTMLDivElement | null;
    showTemplate?.setAttribute("style","display:none")
    
    const removeGrid = document.getElementById("showGrid") as HTMLDivElement | null;
    removeGrid?.setAttribute("style","display:block")
    setid(doc.id);

    setname(doc.data().name);
    // console.log(
    //   `${AppConfig.routes.member.scribble}?template=${doc.id}&name=${
    //     doc.data().name
    //   }`
    // );
  };
  // history.push(`${AppConfig.routes.member.scribble}?template=${doc.id}&name=${doc.data().name}`);

  return (
    <TemplatesPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>
            {pageTitle}|{loading}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="content-full">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{pageTitle}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
          enterkeyhint="search"
          inputMode="search"
          placeholder="Search"
          showCancelButton="focus"
        ></IonSearchbar>
        {true && (
          <>
            {true && (
              <>
                <div>
                  <IonList>
                    <IonListHeader>Popular</IonListHeader>
                    <IonSlides
                      pager={true}
                      options={sliderOptions}
                      className="ion-padding popular-templates-swiper"
                    >
                      {value &&
                        value.docs.map((doc) => (
                          <IonSlide
                            key={doc.id}
                            className="ion-no-padding main-slide "
                            style={{ width: "178px !important" }}
                          >
          
                            <IonCard id={doc.data().name}
                             className={`ion-no-margin ion-padding pop-template-card  ${
                                id == doc.id ? "swiper-slide-actives" : ""
                              }`}
                              onClick={() => redirectToScribble(doc)}
                            >
                              <div>
                                <img
                                  className="template-popular-img"
                                  src={
                                    doc.data().imgSrc ||
                                    `${altImage}?text=${doc.data().name}`
                                  }
                                  alt={doc.data().title}
                                />
                              </div>
                              <IonCardContent className="ion-no-padding">
                                <div className="popular-container">
                                  {doc
                                    .data()
                                    .name.split(" ")
                                    .map((word: string) => (
                                      <span key={word}>{word}</span>
                                    ))}
                                </div>
                              </IonCardContent>
                            </IonCard>
                          </IonSlide>
                        ))}
                    </IonSlides>
                  </IonList>
                </div>
                  <div id="showGrid" className="hideGrid ">
                  <IonRow className="content-grid">
                    <IonCol className="grid-one">
                      <Scribble id={id} name={name} />
                    </IonCol>
                  </IonRow>          
                  </div>
              </>
            )}
             <div className="ion-padding-bottom" id="showTemplate">
              <IonList className="ion-padding-bottom">
                <IonListHeader>Recently used</IonListHeader>
                <div className="template-list">
                  {filteredTemplates &&
                    filteredTemplates.map((doc) => (
                      <IonCard
                        key={doc.id}
                        onClick={() => redirectToScribble(doc)}
                        className="template-list-item ion-no-margin ion-margin-horizontal"
                      >
                        <IonCardContent className="ion-no-padding ion-padding">
                          <div className="template-container">
                            <div>
                              <img
                                className="template-img"
                                src={doc.data().imgSrc || `${altImage}?text=${doc.data().name}`}
                                alt={doc.data().title}
                              />
                            </div>
                            <div className="ion-padding-start">
                              <h3>{doc.data().name}</h3>
                              <span className="ion-text-wrap">{doc.data().description}</span>
                            </div>
                          </div>
                        </IonCardContent>
                      </IonCard>
                    ))}
                </div>
                {!filteredTemplates.length && (
                  <IonItem lines="none" className="ion-text-center">
                    <IonLabel>No templates found</IonLabel>
                  </IonItem>
                )}
              </IonList>
            </div>
          </>
        )}
      </IonContent>
    </TemplatesPage>
  );
};

export default Templates;
