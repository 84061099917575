import { Preferences } from '@capacitor/preferences';
import { isPlatform } from '@ionic/react';
import { createContext, useEffect, useReducer } from 'react';
import { AppConfig } from '../../config/app.config';
import Subscription from '../domain/Subscription';

type ToastState = {
  isOpen: boolean;
  message?: string;
  position?: ToastPosition;
};

type LoadingState = {
  isOpen: boolean;
  message?: string;
};

type AppState = {
  theme: string;
  loading: LoadingState;
  toast: ToastState;
  isDesktop: boolean;

  subscriptions?: Array<Subscription>;

  dispatch?: React.Dispatch<Action>;
};

export enum ToastPosition {
  top = 'top',
  middle = 'middle',
  bottom = 'bottom',
}

const initialState: AppState = {
  theme: AppConfig.theme.light,
  loading: { isOpen: false, message: '' },
  toast: { isOpen: false, message: '', position: ToastPosition.bottom },
  isDesktop: true,

  subscriptions: [],
};

export enum AppAction {
  ToggleTheme = 'TOGGLE_THEME',
  ToggleLoading = 'TOGGLE_LOADING',
  ToggleToast = 'TOGGLE_TOAST',
  SetIsDesktop = 'SET_IS_DESKTOP',

  ManageSubscriptions = 'MANAGE_SUBSCRIPTIONS',
}

type Action =
  | { type: AppAction.ToggleTheme; payload: string }
  | { type: AppAction.ToggleLoading; payload: LoadingState }
  | { type: AppAction.ToggleToast; payload: ToastState }
  | { type: AppAction.SetIsDesktop; payload: boolean }
  | { type: AppAction.ManageSubscriptions; payload: Array<Subscription> };

export const appReducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case AppAction.ToggleTheme:
      return { ...state, theme: action.payload };
    case AppAction.ToggleLoading:
      return { ...state, loading: action.payload };
    case AppAction.ToggleToast:
      return { ...state, toast: action.payload };
    case AppAction.SetIsDesktop:
      return { ...state, isDesktop: action.payload };
    case AppAction.ManageSubscriptions:
      return { ...state, subscriptions: action.payload };
    default:
      return state;
  }
};

export const AppContext = createContext<AppState>(initialState);

type Props = { children: React.ReactNode };

export const AppContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    localStorage.removeItem("CapacitorStorage.THEME")
    const setTheme = async () => {
      const { value: currentTheme } = await Preferences.get({ key: AppConfig.storageKeys.theme });
      if (currentTheme) {
        dispatch({ type: AppAction.ToggleTheme, payload: currentTheme || AppConfig.theme.light });
      } else if (window.matchMedia) {
        const isDarkMode = window.matchMedia('(prefers-color-scheme: light)');
        dispatch({ type: AppAction.ToggleTheme, payload: isDarkMode.matches ? AppConfig.theme.light : '' });
        // dispatch({ type: AppAction.ToggleTheme, payload: isDarkMode.matches ? AppConfig.theme.dark : '' });
      }
    };

    const setIsDesktop = () => {
      dispatch({
        type: AppAction.SetIsDesktop,
        payload: isPlatform('desktop') || window.matchMedia('(min-width: 768px)').matches,
      });
    };

    setTheme();
    setIsDesktop();
  }, []);

  return <AppContext.Provider value={{ ...state, dispatch }}>{children}</AppContext.Provider>;
};
