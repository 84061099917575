import { IonCard } from '@ionic/react';
import styled from 'styled-components';

export const ProductCardComponent = styled(IonCard)`
  .select-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .img-prod-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img-prod {
    max-width: 120px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  h2 {
    font-size: 2rem;
    font-weight: bold;
  }

  .subscription-features-container {
    display: flex;
    flex-direction: column;
  }

  .subscription-feature {
    font-size: 1rem;
  }

  .price {
    font-size: 1.8rem;
    font-weight: bold;
    margin-left: 4px;
  }
`;
