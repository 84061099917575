import { IonItem } from '@ionic/react';
import styled from 'styled-components';

export const HistoryIonItem = styled(IonItem)`
  .choice {
    display: flex;
    flex-direction: column;
    white-space: break-spaces !important;
  }

  .summary-container {
    display: flex;
    justify-content: flex-end;
  }

  .template-name {
    font-size: 1.4rem;
  }

  .words-count {
    font-size: 0.9rem;
    color: var(--ion-color-primary);
  }

  .credits {
    font-size: 0.8rem;
    margin: 4px 0;
    color: var(--ion-color-tertiary);

    display: flex;
    align-items: flex-end;
  }

  .small-icon {
    width: 2rem;
  }

  .template-header {
    display: flex;
    justify-content: space-between;
  }

  .credits-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  h3 {
    font-size: 1rem;
    font-weight: 700;
  }
`;
