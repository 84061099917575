import { IonButton, IonIcon, IonLabel, IonNote } from '@ionic/react';
import { cardOutline, copyOutline } from 'ionicons/icons';
import TimeAgo from 'react-timeago';
import { AppConfig } from '../../../config/app.config';
import { useToast } from '../../../core/hooks/useToast';
import { HistoryIonItem } from './HistoryItem.styles';
import parse from 'html-react-parser';


type Props = { item: any; prompt: string };

const HistoryItem: React.FC<Props> = ({ item, prompt }) => {
  const choices = item?.text || item?.data?.choices || item?.choices;
  prompt = prompt || item.prompt?.toString() || item.data?.prompt?.toString() || '';
  const { toggleToast } = useToast();

  const replaceLineBreak = (text: string) => {
    return text
      .split('\\n')
      .filter((text) => text)
      .map((innerValue) => (
        GFG_Fun(innerValue)?
        <span dangerouslySetInnerHTML={{__html: innerValue.trim()}} key={innerValue} className="ion-text-wrap"></span>
        :  <span key={innerValue} className="ion-text-wrap">
        {innerValue.trim()}
      </span>
      ));
  };

  

  function GFG_Fun( str:any ) {
      let innerHTML = 
    /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(str);
    return innerHTML
    }

  const onCopy = (textToCopy: string) => { 
    navigator.clipboard.writeText(textToCopy).then(() => toggleToast(true, AppConfig.messages.copiedToClipboard));
    console.log("textToCopy",textToCopy)
    
  }

  return (
    <HistoryIonItem>
      <IonLabel className="ion-text-wrap">
        <>
          <div className="template-header">
            <span className="template-name">{item?.template?.name}</span>
            {prompt && (
              <IonNote className="credits-container">
                <span className="credits">
                  <IonIcon icon={cardOutline} size="small"></IonIcon>&nbsp;{item.data.usage.total_tokens}
                </span>
                <small>
                  {/* <span>{item.createdAt.toDate().toLocaleDateString()}</span> */}
                  <TimeAgo date={item?.createdAt?.toDate().toString() || ''} />
                </small>
              </IonNote>
            )}
          </div>
          <div className="medium-text">
            {item.formSchema &&
              item.formSchema.map((element: any) => (
                <div key={element.label} className="ion-padding-bottom">
                  <h3>{element.label}</h3>
                  {/* <span>{item.prompt && item.prompt?.find((p: any) => element.name === p.key).value}</span> */}
                  
                  {/* <div dangerouslySetInnerHTML={{__html: this.state.content}}></div> */}
                  <div>{item.prompt && item.prompt.map((p:any , i:any) => (<div key={i}>{element.name === p.key?GFG_Fun(p.value)?<p dangerouslySetInnerHTML={{__html: p.value}}/> : p.value + "" : "" } </div> ))}</div>
                  <>
                  
                  </>
                </div>
              ))}
          </div>
          <h3 className="ion-padding-bottom">Choices</h3>
          {choices.map(
            (choice: any) =>
              choice.text && (
                <div key={choice}>
                  <div className="choice">{parse(choice.text)}</div>
                  <div className="ion-margin-top summary-container">
                    <IonButton fill="outline" size="small" color="secondary" onClick={() => onCopy(choice.text.replace(/\n\n/,""))}>
                      <>
                      </>
                      Copy
                      <IonIcon slot="end" icon={copyOutline} color="secondary" />
                    </IonButton>
                  </div>
                </div>
              )
          )}

        </>
      </IonLabel>
    </HistoryIonItem>
  );
};

export default HistoryItem;
