import { onAuthStateChanged } from 'firebase/auth';
import { createContext, useEffect, useReducer } from 'react';
import { auth } from '../../config/firebase.config';

type AuthState = {
  user: any;
  authIsReady?: boolean;
  dispatch?: React.Dispatch<Action>;
};

export const initialState: AuthState = {
  user: null,
  authIsReady: false,
  dispatch: () => undefined,
};

export enum AuthAction {
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  AuthIsReady = 'AUTH_IS_READY',
  DeleteUser = 'DELETE_USER',
}

type Action =
  | { type: AuthAction.Login; payload: any }
  | { type: AuthAction.Logout }
  | { type: AuthAction.AuthIsReady; payload: any }
  | { type: AuthAction.DeleteUser };

export const authReducer = (state: AuthState, action: Action) => {
  switch (action.type) {
    case AuthAction.Login:
      return { ...state, user: action.payload };
    case AuthAction.Logout:
      return { ...state, user: null };
    case AuthAction.AuthIsReady:
      return { user: action.payload, authIsReady: true };
    case AuthAction.DeleteUser:
      return { ...state, user: null };
    default:
      return state;
  }
};

export const AuthContext = createContext<AuthState>(initialState);

type Props = { children: React.ReactNode };

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  });

  useEffect(() => {
    const unSub = onAuthStateChanged(auth, (user) => {
      dispatch({ type: AuthAction.AuthIsReady, payload: user });

      unSub();
    });
  }, []);

  return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};
