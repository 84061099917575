import {
  IonButton,
  IonCol,
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  useIonAlert,
} from '@ionic/react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ErrorText from '../../../../components/ErrorText';
import { AppConfig } from '../../../../config/app.config';
import { auth } from '../../../../config/firebase.config';
import { useLoading } from '../../../../core/hooks/useLoading';
import { ForgotPasswordPage } from './ForgotPassword.styles';

const ForgotPassword: React.FC = () => {
  const { toggleLoading } = useLoading();
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);

  const resetForm = useFormik({
    initialValues: { email: '' },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: async (formValues) => {
      try {
        toggleLoading(true, 'Sending password reset email...');

        const response = await sendPasswordResetEmail(auth, formValues.email);
        console.debug(response);
        showPasswordResetEmailSentAlert();
      } catch (err: any) {
        // TODO: handle error
        console.error(err);
        setError(err.message || AppConfig.messages.error.default);
      } finally {
        toggleLoading();
      }
    },
  });

  const showPasswordResetEmailSentAlert = () =>
    presentAlert({
      header: AppConfig.messages.resetPassword.header,
      message: AppConfig.messages.resetPassword.success,
      buttons: [
        {
          text: 'Ok',
          cssClass: 'alert-button-cancel',
          handler: () => history.push(AppConfig.routes.public.login, { direction: 'none' }),
        },
      ],
    });

  return (
    <ForgotPasswordPage>
      <IonContent fullscreen>
        <form noValidate onSubmit={resetForm.handleSubmit} className="page-centered max-form-width">
          <IonList lines="none" className="full-width">
            <IonImg src="/assets/icon/logo.svg" className="logo-auth" />
            <h2 className="ion-no-padding ion-no-margin ion-padding-horizontal">
              {AppConfig.pageTitles.forgotPassword}
            </h2>
            <IonItem>
              <IonLabel position="stacked" color="primary">
                Email
              </IonLabel>

              <IonInput
                id="email"
                name="email"
                type="email"
                spellCheck={false}
                autocapitalize="off"
                onIonChange={resetForm.handleChange}
                onIonBlur={resetForm.handleBlur}
                value={resetForm.values.email}
                autocomplete="username"
              ></IonInput>
              {resetForm.touched.email && resetForm.errors.email ? (
                <ErrorText message={resetForm.errors.email}></ErrorText>
              ) : null}
            </IonItem>
          </IonList>

          {error && <ErrorText message={error}></ErrorText>}

          <IonRow className="ion-padding-horizontal full-width">
            <IonCol>
              <IonButton type="submit" expand="block">
                Submit
              </IonButton>
            </IonCol>
          </IonRow>

          <div className="ion-margin-top">
            Back to{' '}
            <Link to={AppConfig.routes.public.login}>
              <b>Login</b>
            </Link>
          </div>

          <div className="ion-margin-top">
            New to ScribeSpaceAI?{' '}
            <Link to={AppConfig.routes.public.signup}>
              <b>Signup</b>
            </Link>
          </div>
        </form>
      </IonContent>
    </ForgotPasswordPage>
  );
};

export default ForgotPassword;
