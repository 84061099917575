import { IonApp, IonLoading, IonRouterOutlet, IonSplitPane, IonToast, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { Redirect, Route } from 'react-router-dom';
import './app.scss';
import { AppConfig } from './config/app.config';
import { useAppContext } from './core/hooks/useAppContext';
import { useLoading } from './core/hooks/useLoading';
import { useToast } from './core/hooks/useToast';
import TemplateAdmin from './modules/admin/TemplateAdmin';
import { useAuthContext } from './modules/auth/hooks/useAuthContext';
import ForgotPassword from './modules/auth/pages/ForgotPassword';
import Login from './modules/auth/pages/Login';
import Signup from './modules/auth/pages/Signup';
import PrivacyPolicy from './modules/legal/PrivacyPolicy';
import TermsAndConditions from './modules/legal/TermsAndConditions';
import MemberTabs from './modules/members/MemberTabs';
import Menu from './modules/Menu';
/* Theme variables */
import './theme/variables.scss';
import { useEffect } from 'react';

setupIonicReact();

const App: React.FC = () => {
  const { user, authIsReady } = useAuthContext();
  const { theme, isDesktop } = useAppContext();
  const { isLoadingOpen, loadingMessage } = useLoading();
  const { isToastOpen, toastMessage, toastPosition, toggleToast } = useToast();

  useEffect(() => {
    localStorage.removeItem("CapacitorStorage.THEME")
  },[]);

  return (
    <IonApp className={theme}>
      {authIsReady && (
        <IonReactRouter>
          <IonSplitPane contentId="main" when="(min-width: 576px)">
            {user && isDesktop && <Menu />}
            <IonRouterOutlet id="main">
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/signup" component={Signup} />
              <Route path="/terms-and-conditions" component={TermsAndConditions} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/admin/templates" component={TemplateAdmin} />
              <Route path="/member" render={() => <MemberTabs />} />
              <Route path="/" exact>
                {!user && <Redirect to="/login" />}
                {user && <Redirect exact path="/" to={AppConfig.routes.member.home} />}
              </Route>
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      )}
      <IonLoading isOpen={isLoadingOpen} message={loadingMessage} spinner="crescent" />
      <IonToast
        position={toastPosition}
        isOpen={isToastOpen}
        message={toastMessage}
        onDidDismiss={() => toggleToast(false)}
        duration={AppConfig.delay.toastDuration}
      />
    </IonApp>
  );
};
export default App;
