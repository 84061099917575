import { Preferences } from '@capacitor/preferences';
import { AppConfig } from '../../config/app.config';
import { AppAction } from '../context/AppContext';
import { useAppContext } from './useAppContext';

export const useAppTheme = () => {
  const { dispatch, theme } = useAppContext();

  const toggleTheme = async () => {
    if (dispatch) {
      const selectedTheme = theme === AppConfig.theme.light ? AppConfig.theme.dark : AppConfig.theme.light;
      await Preferences.set({ key: AppConfig.storageKeys.theme, value: selectedTheme });

      dispatch({ type: AppAction.ToggleTheme, payload: selectedTheme });
    }
  };

  return { theme, toggleTheme };
};
