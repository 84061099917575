import { AppAction, ToastPosition } from '../context/AppContext';
import { useAppContext } from './useAppContext';

export const useToast = () => {
  const { dispatch, toast } = useAppContext();

  const showToast = (message: string) => {
    if (dispatch) {
      dispatch({ type: AppAction.ToggleToast, payload: { isOpen: true, message } });
    }
  };

  const hideToast = () => {
    if (dispatch) {
      dispatch({ type: AppAction.ToggleToast, payload: { isOpen: false } });
    }
  };

  const toggleToast = (isOpen: boolean, message = '', position = ToastPosition.bottom) =>
    isOpen ? showToast(message) : hideToast();

  return { toggleToast, isToastOpen: toast.isOpen, toastMessage: toast.message, toastPosition: toast.position };
};
