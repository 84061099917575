import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const ProfilePage = styled(IonPage)`
  .subscription-expiring {
    color: var(--ion-color-danger);
  }

  .subscription-renew {
    color: var(--ion-color-step-650);
  }

  .email {
    font-size: 0.9rem;
  }

  .display-name {
    font-weight: bold;
  }
`;
