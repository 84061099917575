import { IonList } from '@ionic/react';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import HistoryItem from '../HistoryItem';

type Props = { history: QuerySnapshot<DocumentData> | null };

const HistoryList: React.FC<Props> = ({ history }) => {
  return (
    history && (
      <IonList className="ion-margin-bottom">
        {history.docs.map((doc) => (
          <HistoryItem key={doc.id} item={doc.data()} prompt={'default'} />
        ))}
      </IonList>
    )
  );
};

export default HistoryList;
