import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
import { copyOutline } from "ionicons/icons";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import ReactQuill from "react-quill";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { AppConfig } from "../../../config/app.config";
import templateFormSchemaRef from "../../../core/firebase/template-formSchemaRef";
import { useFirebaseFunctions } from "../../../core/hooks/useFirebaseFunctions";
import { useLoading } from "../../../core/hooks/useLoading";
import { useToast } from "../../../core/hooks/useToast";
import { useAuthContext } from "../../auth/hooks/useAuthContext";
import { useScribbleHistory } from "../../auth/hooks/useScribbleHistory";
import HistoryList from "../History/HistoryList";
import HistoryItem from "../HistoryItem";
import { Form, SelectField, TextAreaField, TextField } from "./FormElements";
import { ScribblePage } from "./Scribble.styles";

const modulesEditor = {
  toolbar: [
    //[{ 'font': [] }],
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
    ["clean"],
  ],
};

const formatsEditor = [
  //'font',
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "color",
  "background",
];

let htmlContent =
  '<div style="text-align:center"><h1 style="font-weight:bold">Tired of all the back and forth?</h1><br><div style="font-weight:medium;width:18%; text-align:center">You can edit and style your text right here.Once you"re done formatting your text, you can "save" this content and retrieve it later from the "History" tab.</div></div>';

// const Scribble = () => {
const Scribble = ({ ...props }): JSX.Element => {
  const [Editorvalue, setEditorValue] = useState(htmlContent);
  const [result, setResult] = useState<any>(null);
  const { scribble } = useFirebaseFunctions();
  const { toggleLoading } = useLoading();
  const { search } = useLocation();
  const template = props.id || "default";
  const pageTitle = props.name || AppConfig.pageTitles.home;
  // const template = queryString.parse(search).template || 'default';
  // const pageTitle = queryString.parse(search).name || AppConfig.pageTitles.home;
  const [value, loading] = useCollection(
    templateFormSchemaRef(template.toString())
  );
  const { toggleToast } = useToast();
  const [formData, setFormData] = useState<any>({});
  const [formSchema, setFormSchema] = useState<any>({});

  const [validationSchema, setValidationSchema] = useState({});
  const [prompt, setPrompt] = useState<string>("");
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const { user } = useAuthContext();
  const { filteredHistory } = useScribbleHistory(user.uid, template as string);

  useEffect(() => {
    toggleLoading(loading, loading ? "scribing..." : "");
    let formSchema: any = {};
    if (value?.docs) {
      value.docs.forEach(
        (doc) => (formSchema[doc.data().name.toLowerCase()] = doc.data())
      );
      setFormSchema(formSchema);
      initForm(formSchema);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const initForm = (formSchema: any) => {
    let _formData: any = {};
    let _validationSchema: any = {};
    let _beforeAfter: any = {};

    for (const key of Object.keys(formSchema)) {
      _formData[key] = "";

      if (formSchema[key].type === "text") {
        _validationSchema[key] = Yup.string();
      } else if (formSchema[key].type === "textarea") {
        _validationSchema[key] = Yup.string();
      } else if (formSchema[key].type === "email") {
        _validationSchema[key] = Yup.string().email();
      } else if (formSchema[key].type === "select") {
        _validationSchema[key] = Yup.string().oneOf(
          formSchema[key].options.map((o: any) => o.value)
        );
      }

      if (formSchema[key].required) {
        const requiredMessage =
          formSchema[key].requiredMessage ||
          `${formSchema[key].label} is required`;
        _validationSchema[key] =
          _validationSchema[key].required(requiredMessage);
      }

      if (formSchema[key]?.before) {
        _beforeAfter[key] = { before: formSchema[key].before };
      }

      if (formSchema[key]?.after) {
        _beforeAfter[key] = {
          ..._beforeAfter[key],
          after: formSchema[key].after,
        };
      }
    }

    setFormData(_formData);
    setValidationSchema(Yup.object().shape({ ..._validationSchema }));
  };

  const getFormElement = (elementName: any, elementSchema: any) => {
    // TODO: elementName is not required
    const props = {
      name: elementName,
      label: elementSchema.label,
      placeholder: elementSchema.placeholder,
      options: elementSchema.options,
      rows: elementSchema.rows,
    };

    if (elementSchema.type === "text" || elementSchema.type === "email") {
      return <TextField {...props} />;
    }

    if (elementSchema.type === "textarea") {
      return <TextAreaField {...props} />;
    }

    if (elementSchema.type === "select") {
      return <SelectField {...props} />;
    }
  };

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    const creditDeduct = true;
    const prompts = Object.keys(values).map((key) => ({
      key,
      value: values[key].trim(),
    }));

    setPrompt(prompt);
    setSubmitting(false);
    setResult(null);
    try {
      toggleLoading(true, "Scribing...");

      const result = await scribble(template.toString(), prompts, creditDeduct);
      setResult(result);
    } catch (err: any) {
      handleError(err);
    } finally {
      toggleLoading();
    }
  };

  const showInsufficientCreditsError = () => {
    return presentAlert({
      header: AppConfig.messages.insufficientCredits.header,
      message: AppConfig.messages.insufficientCredits.message,
      buttons: [
        {
          text: "Ok",
          cssClass: "alert-button-cancel",
        },
        {
          text: "Manage Subscription",
          cssClass: "alert-button-confirm",
          handler: () => history.push(AppConfig.routes.member.subscription),
        },
      ],
    });
  };

  const handleError = (err: any) => {
    if (err.code.includes("permission-denied")) {
      return showInsufficientCreditsError();
    }
  };

  // editor code
  const submitEditor = async () => {
    const checkcredits = false;
    // const prompts = Object.keys(values).map((key) => ({ key, value: values[key].trim() }));
    setResult(null);
    try {
      toggleLoading(true, "Saving...");
      const prompts = Object.keys(formSchema).map((key) => key);
      // prompt[0].value = value
      let prompt = [
        {
          key: prompts[0],
          value: Editorvalue,
        },
      ];

      const result = await scribble(template.toString(), prompt, checkcredits);
      setResult(result);
    } catch (err: any) {
      handleError(err);
    } finally {
      toggleLoading();
    }

    // setResult(result);

    // const prompts = Object.keys(formSchema).map((key) => ( {Object.keys(key).map((keys) => ({ key, value: values[key].trim() })));
  };

  const handleChangeEditor = (
    content: any,
    delta: any,
    source: any,
    editor: any
  ) => {
    setEditorValue(editor.getHTML());
  };

  const onCopy = () => {
    var htmlContent = Editorvalue;
    var strippedHtml = htmlContent.replace(/<[^>]+>/g, "");
    navigator.clipboard
      .writeText(strippedHtml)
      .then(() => toggleToast(true, AppConfig.messages.copiedToClipboard));
  };

  return (
    <ScribblePage>
      <IonRow className="content-grid ">
        <IonCol className="grid-one">
          <div className="scribble-parent-container">
            <div className="scribble-container">
              {formSchema && (
                <Form
                  enableReinitialize
                  initialValues={formData}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <IonList lines="none">
                    {Object.keys(formSchema).map((key: string) => (
                      <IonItem key={key}>
                        {getFormElement(key, formSchema[key])}
                      </IonItem>
                    ))}

                    <IonRow>
                      <IonCol></IonCol>
                    
                      <IonCol></IonCol>
                      <IonCol>
                        <IonItem className="ion-padding-top ">
                          <IonButton
                            className="scribeButton "
                            type="submit"
                            expand="block"
                            slot="end"
                            size="default"
                          >
                            Scribe
                          </IonButton>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonList>
                </Form>
              )}
            </div>
            <div className="scribble-history">
              {result && (
                <IonList className="ion-margin-bottom">
                  <>
                    <HistoryItem item={result} prompt={prompt} key={result} />
                  </>
                  <></>
                </IonList>
              )}

              {filteredHistory && (
                <HistoryList history={filteredHistory}></HistoryList>
              )}

              <></>
            </div>
          </div>
        </IonCol>
        <IonCol class="grid-two ion-margin-top">
          <div className="ion-margin-top">
            {/* {Object.keys(formSchema).map((key: string) => ( */}

            <ReactQuill
              theme="snow"
              modules={modulesEditor}
              formats={formatsEditor}
              defaultValue=""
              value={Editorvalue}
              onChange={handleChangeEditor}
            />
            <IonGrid className="ion-no-padding ion-margin-top">
              <IonRow className="buttoncs  ion-margin-top">
                <IonCol></IonCol>
                <IonCol>
                  <IonButton
                    type="submit"
                    fill="outline"
                    className="btnSave  rid-two ion-margin-top"
                    expand="block"
                    onClick={() => onCopy()}
                  >
                    <img
                      className="copy-btn"
                      src="https://s2.svgbox.net/octicons.svg?ic=copy"
                      alt="Icon"
                    />
                    Copy
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton
                    type="submit"
                    className="btnSave rid-two ion-margin-top"
                    expand="block"
                    onClick={submitEditor}
                  >
                    Save
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>

            {/* ))} */}
          </div>
        </IonCol>
      </IonRow>

      {/* <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader> */}
      {/* <IonContent>
      
      </IonContent> */}
    </ScribblePage>
  );
};

export default Scribble;
