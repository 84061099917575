import { IonInput, IonLabel, IonSelect, IonSelectOption, IonTextarea } from '@ionic/react';
import { ErrorMessage, Field, Form as FormikForm, Formik, useFormikContext } from 'formik';
import ErrorText from '../../../components/ErrorText';

export function Form(props: any) {
  return (
    <Formik {...props}>
      <FormikForm className="needs-validation" noValidate>
        {props.children}
      </FormikForm>
    </Formik>
  );
}

export function TextField(props: any) {
  const { name, label, placeholder, ...rest } = props;
  return (
    <>
      {label && (
        <IonLabel position="stacked" className="ion-text-wrap">
          {label}
        </IonLabel>
      )}
      <IonInput hidden></IonInput>
      <Field
        className="form-control native-input sc-ion-input-ios sc-ion-input-md input-padding"
        type="text"
        name={name}
        id={name}
        placeholder={placeholder || ''}
        {...rest}
      />
      <ErrorMessage name={name} render={(msg) => <ErrorText message={msg}></ErrorText>} />
    </>
  );
}

export function TextAreaField(props: any) {
  const { name, label, placeholder, rows, ...rest } = props;
  return (
    <>
      {label && (
        <IonLabel position="stacked" className="ion-text-wrap">
          {label}
        </IonLabel>
      )}
      <div className="textarea-wrapper sc-ion-textarea-ios">
        <IonTextarea hidden></IonTextarea>
        <Field
          className="native-textarea sc-ion-textarea-ios sc-ion-textarea-md input-padding"
          as="textarea"
          name={name}
          id={name}
          rows={rows || 5}
          placeholder={placeholder || ''}
          {...rest}
        />
      </div>
      <ErrorMessage name={name} render={(msg) => <ErrorText message={msg}></ErrorText>} />
    </>
  );
}

export function SelectField(props: any) {
  const { name, label, options } = props;
  return (
    <>
      {label && (
        <IonLabel position="stacked" className="ion-text-wrap">
          {label}
        </IonLabel>
      )}
      <IonSelect id={name} name={name}>
        {options.map((opt: any, index: any) => (
          <IonSelectOption value={opt.value} key={opt.value}>
            {opt.label || opt.value}
          </IonSelectOption>
        ))}
      </IonSelect>
      <Field as="select" id={name} name={name} className="ios input-padding">
        <option key="choose">Choose...</option>
        {options.map((opt: any, index: any) => (
          <option value={opt.value} label={opt.label || opt.value} key={opt.value} />
        ))}
      </Field>

      <ErrorMessage name={name} render={(msg) => <ErrorText message={msg}></ErrorText>} />
    </>
  );
}

export function SubmitButton(props: any) {
  const { title, ...rest } = props;
  const { isSubmitting } = useFormikContext();

  return (
    <button type="submit" {...rest} disabled={isSubmitting}>
      {title}
    </button>
  );
}
