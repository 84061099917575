import { useCollection } from 'react-firebase-hooks/firestore';
import historyCollectionRef from '../../../core/firebase/history-collectionRef';
import { useSubscription } from '../../../core/hooks/useSubscription';

export const useScribbleHistory = (userId: string, templateName: string = '') => {
  const { subscriptions } = useSubscription();
  const [historyItems, loading] = useCollection(historyCollectionRef(userId));
  const [filteredHistory] = useCollection(historyCollectionRef(userId, templateName)); // TODO: not working

  const creditsUsed = () => {
    if (historyItems && historyItems.docs.length > 0 && subscriptions && subscriptions.length > 0) {
      const credits = historyItems.docs.reduce((acc, doc) => {
        const item = doc.data();
        return item.data.usage.total_tokens ? acc + item.data.usage.total_tokens : acc;
      }, 0);

      return credits;
    }
    return 0;
  };

  return { historyItems, loading, filteredHistory, creditsUsed };
};
