import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle } from '@ionic/react';
import { pencilOutline, settingsOutline } from 'ionicons/icons';
import { useLocation } from 'react-router-dom';
import { AppConfig } from '../../config/app.config';
import { useAuthContext } from '../auth/hooks/useAuthContext';
import './Menu.scss';
import { MenuItem } from './MenuItem';

const memberMenuItems: Array<MenuItem> = [
  {
    title: 'History',
    icon: '/assets/icon/history.svg',
    // iconColor: 'primary',
    path: AppConfig.routes.member.history,
  } as MenuItem,
  {
    title: 'Scribe',
    // icon: '/assets/icon/templates.svg',
    icon: pencilOutline,
    // iconColor: 'secondary',
    path: AppConfig.routes.member.templates,
  } as MenuItem,
  {
    title: 'Settings',
    icon: settingsOutline,
    height: '20px',
    size: '69',
    // iconColor: 'danger',
    path: AppConfig.routes.member.settings,
  } as MenuItem,
];

const Menu: React.FC = () => {
  const location = useLocation();
  const { authIsReady } = useAuthContext();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonImg src="/assets/icon/logo.svg" className="ion-margin" />
      <IonContent>
        {authIsReady && (
          <IonList className="mt-10">
            {memberMenuItems.map((item, index) => (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={location.pathname === item.path ? 'selected' : ''}
                  routerLink={item.path}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" ios={item.icon} md={item.icon} color={item.iconColor} />
                  <IonLabel>{item.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            ))}
          </IonList>
        )}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
