import { IonButton, IonCardContent, IonCardHeader } from '@ionic/react';
import Price from '../../../core/domain/Price';
import Product from '../../../core/domain/Product';
import { ProductCardComponent } from './ProductCard.styles';

type Props = {
  product: Product;
  price: Price;
  buttonText?: string;
  productOnClick?: (priceId?: string) => void;
};

const ProductCard: React.FC<Props> = ({ product, price, buttonText = '', productOnClick }) => {
  return (
    <ProductCardComponent className="product-card">
      <IonCardHeader className="ion-no-margin">
        <h2 className="ion-no-margin ion-text-center">{product.name}</h2>
      </IonCardHeader>
      <div className="img-prod-container">
        <img src={product.images[0]} alt={product.name} className="img-prod" />
      </div>
      <IonCardContent>
        <div className="subscription-features-container">
          {product.credits && <div className="ion-text-center subscription-feature">{product.credits} Credits</div>}
          {product.description.split(',').map((feature: string) => (
            <div className="ion-text-center subscription-feature" key={feature}>
              {feature}
            </div>
          ))}
        </div>
      </IonCardContent>
      {productOnClick && price && (
        <div className="select-container ion-padding-horizontal ion-padding-bottom">
          <div>
            $<span className="price">{price.amount}</span>/month
          </div>
          <IonButton color="tertiary" onClick={() => productOnClick(price.priceId)}>
            {buttonText || 'Select'}
          </IonButton>
        </div>
      )}
    </ProductCardComponent>
  );
};

export default ProductCard;
