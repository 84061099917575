import { AppAction } from '../context/AppContext';
import { useAppContext } from './useAppContext';

export const useLoading = () => {
  const { dispatch, loading } = useAppContext();

  const showLoading = (text: string = '') => {
    if (dispatch) {
      dispatch({ type: AppAction.ToggleLoading, payload: { isOpen: true, message: text } });
    }
  };

  const hideLoading = () => {
    if (dispatch) {
      dispatch({ type: AppAction.ToggleLoading, payload: { isOpen: false } });
    }
  };

  const toggleLoading = (loading: boolean = false, text: string = '') => (loading ? showLoading(text) : hideLoading());

  return { toggleLoading, isLoadingOpen: loading.isOpen, loadingMessage: loading.message };
};
