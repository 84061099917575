import { IonBackButton, IonButtons, IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { AppConfig } from '../../../config/app.config';
import { SubscriptionsErrorPage } from './SubscriptionsError.styles';

const SubscriptionsError = () => {
  const pageTitle = 'Subscription';

  return (
    <SubscriptionsErrorPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref={AppConfig.routes.member.settings} />
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <h1>Wooops! Looks like something didn't go as well as we thought!</h1>
        <p>While technology can break, our support team is ready to assist</p>
        <p>
          Shoot us an email at: <a href="mailto:support@ScribeSpace.ai">support@ScribeSpace.ai</a>
        </p>
      </IonContent>
    </SubscriptionsErrorPage>
  );
};

export default SubscriptionsError;
