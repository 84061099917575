import { IonPage } from '@ionic/react';
import styled from 'styled-components';

export const SvgImg = styled.img`
  width: 36px;
  background-color: #fff;
  border-radius: 20%;
`;

export const SettingsPage = styled(IonPage)`
  .subscription-expiring {
    color: var(--ion-color-danger);
  }

  .subscription-renew {
    color: var(--ion-color-navigation);
  }

  .openai-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .all-plans {
    color: var(--ion-color-navigation);
  }

  .credits-used {
    display: flex;
    align-items: center;

    margin-right: 0;

    color: var(--ion-color-tertiary);
    ion-icon {
      font-size: 1.5rem;
    }
  }

  .display-name {
    font-size: 1.4rem;
  }
`;
