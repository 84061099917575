import { collection, getFirestore, orderBy, query, where } from 'firebase/firestore';
import { firebaseApp } from '../../config/firebase.config';

const historyCollectionRef = (userId: string, templateName: string = '') =>
  templateName
    ? query(
        collection(getFirestore(firebaseApp), `logs/${userId}/history`),
        where('template.name', '==', templateName), // TODO: where clause is not working
        orderBy('createdAt', 'desc')
      )
    : query(collection(getFirestore(firebaseApp), `logs/${userId}/history`), orderBy('createdAt', 'desc'));

export default historyCollectionRef;
